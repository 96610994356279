import React from 'react'
// import { useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import Anchor from '../components/anchor'
import SEO from '../components/seo'

const ContactPage = () => {

  // const data = useStaticQuery(graphql`
  //   query LogoQuery {
  //     file(relativePath: {eq: "logo.zip"}) {
  //       publicURL
  //     }
  //   }
  // `);
  
  return (
    <Layout textblock={true}>
      <SEO title="Contact" />
      <section className="item">
          <h1>Listeners</h1>
          <p>
            You can find our music on <Anchor href="https://nutriot.bandcamp.com">Bandcamp</Anchor> and most other digital
            stores and streaming services. Our releases are distributed by <Anchor href="https://kompakt.fm/distribution" external={true}>Kompakt Distribution</Anchor>.
          </p>
          
          <h1>Producers</h1>
          <p>
            If you wish to send us demos of your music, please get in touch on <Anchor href="https://soundcloud.com/nutriot" external={true}>SoundCloud</Anchor>
          </p>
          
          <h1>Socialize</h1>
          <p>
            Other than that, you can find us on <Anchor href="https://twitter.com/nutriot" external={true}>Twitter</Anchor> & <Anchor href="https://instagram.com/nutriot" external={true}>Instagram</Anchor>
          </p>
          {/* <Anchor href={data.file.publicURL}>Download</Anchor> */}
      </section>
    </Layout>
  );
}

export default ContactPage
